import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";
import ImageGallery from "react-image-gallery"
const images = [
    {
        original: require('../images/face.png'),
        thumbnail: require('../images/face.png')
    },
]

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Facial Recognition Game</h1>
            <h4>September 2018</h4>
            <p>
                This project was the result of a hackathon I did with a couple of other students when I was a freshman. The goal was to create a game that would give an emotion you were supposed to replicate, and then it would score you based on how well it thought you were expressing the emotion. We developed a model to use by taking a pretrained model and changing it to fit our uses. We then connected the model to an HTML page that would take a picture and pass it on to the backend using Django. This was my first experience with both web development and computer vision, so it was quite the experience trying to figure it all out in 17 hours, but I learned a lot and I think I devote a lot of my passion towards the topics to starting with this project.
            </p>
            <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images}></ImageGallery>
        </ProjectPage>
    </div>)
}

export default IndexPage
